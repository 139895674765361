.footer {
  background-color: var(--secondary-colour);
  height: 5%;
}

.footer_inner {
  background-color: var(--secondary-colour);
  padding: 0;
  margin: 0;
  font-size: 1.5vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyright {
  padding: 0;
  margin: 0;
}
