.tile_container {
  width: 100%;
  background-color: var(--grey-colour);
}

.tile_body {
  display: flex;
  width: 100%;
}

.tile_text_container {
  width: 60%;
}

.tile_image_container {
  width: 40%;
  float: left
}

.tile_title_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--secondary-colour);
}

.tile_title {
  display: flex;
  align-items: center;
  font-family: var(--title-font);
  font-size: 5vh;
  padding: 0;
  margin: 0;
}

.inner_text_container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 90%;
}

.tile_text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  font-size: 3vh;
  text-align: center;
  line-height: 150%;
}

.tile_image {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

@media (max-width: 800px) {
 .tile_image_container {
   width: 100%;
 }

 .tile_text_container {
   width: 100%;
 }

 .tile_body {
   display: block;
 }
}
