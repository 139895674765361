.main_body {
  width: 100%;
  display: flex;
  justify-content: center;
}

.main_body_list {
  width: 100%;
  display: block;
}

.top_image {
  width: 60%;
}

.top_image_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey-colour)
}

.body_tile {
  width: 100%;
  background-color: var(--secondary-colour);
}

.tile_separator {
  background-color: #949494;
  height: 1vh;
}

.gallery {
  height: 5%;
}
