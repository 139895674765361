.MainPage {
  width: 100%;
  height: 100vh;
  color: var(--font-color);
  text-shadow: 5px 5px 5px grey;
  font-size: 400%;
  justify-content: center;
}

.main_page_header {
  width: 100%;
  height: 30%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_body {
  width: 100%
}

@media (max-width: 850px ) {
  .main_page_header {
    font-size: 40%;
    height: 20%;
  }

  .MainPage {
    font-size: 350%;
  }
}
