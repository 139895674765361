.rgb_guesser {
  text-align: center;
  overflow: hidden;
  height: 100%;
}

.rgb_main {
  background-color: #5a7dfb;
  width: 100%;
  height: 100%;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main_box {
  width: 60vh;
  height: 60vh;
  background-color: rgb(255, 12, 23);
  margin-top: 2%;
  display: flex;
}

.result_list {
  margin: 0;
  padding: 0;
}

.user_result_box {
  width: 50%;
  height: 100%;
}

.result_box {
  width: 50%;
  height: 100%;
}

.results_text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50% , -50%);
  -webkit-transform: translate(-50%, -50%);
}

.guess_text {
  margin-top: 10%;
  font-size: 80%;
}

.input_div {
  width: 55vh;
  height: 50px;
  background-color: white;
  margin-top: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_box {
  width: 25%;
  border-radius: 10px;
  margin-right: 5%; 
  margin-left: 5%;
  display: flex;
  align-items: center;
  text-align: center;
}

.input_label {
  display: flex;
  justify-content: center;
  width: 50%;
}

.rgb_button {
 border-radius: 10px;
 background-color: #FB5ACE;
 color: white;
 font-size: 80%;
 font-weight: bold;
}

.submit_button {
  margin-top: 2%;
  width: 55vh;
  height: 50px;
}

.show_leaderboard_button {
  margin-top: 10%;
  width: 80%;
  height: 50px;
}

.side_bar {
  width: 15%;
  font-size: 115%;
  height: 100vh;
  float: left;
  overflow: hidden;
  position: absolute;
  color: white;
}

.right_side_bar {
  width: 15%;
  font-size: 115%;
  height: 100vh;
  overflow: hidden;
  position: absolute;
  left: 85%;
  color: white;
}

.credit {
  font-size: 70%;
}


.leaderboard {
  width: 50%;
  height: 80%;
  background-color: #FBD85A;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 25%;
  top: 10%;
  display: flex;
  justify-content: center;
}

.leaderboard_list {
  width: 80%;
  list-style-type: none; /* Removes bullets (optional) */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
  gap: 10px; /* Adds space between list items (optional) */
}

.leaderboard_row {
  margin-top: 3%;
  background-color: #FB5ACE;
  width: 100%;
  display: flex;
  justify-content: space-between; /* Evenly spaces the paragraphs */
  align-items: center; /* Aligns items vertically in the center */
}

.list_grid_item {
  display: flex;
  justify-content: center;
  width: 33%;
}

.final_result_text {
  font-size: 200%;
  margin: 4%;
  margin-top: 6%;
  padding: 0;
  
}

.next_game {
  padding: 3%;
  font-size: 60%;
  width: 50%;
  height: 100%;
  border-radius: 30px;
}

.enter_name {
  width: 50%;
  height: 20%;
  background-color: #FB5ACE;
  position: absolute;
  left: 25%;
  top: 30%;
}

.enter_name_title_container {
  width: 100%;
  font-size: 200%;
  padding: 0;
  margin: 0;
}

.enter_name_title {
  margin: 3%;
  padding: 0;
}

.enter_name_input_container {
  width: 100%;
}

.enter_name_input {
  width: 40%;
  font-size: 130%;
}
