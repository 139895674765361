.searchable_list {
  width: 100%;
  align-items: center;
  justify-content: center;
}

.search_box_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 10vh;
  padding-bottom: 2%;
}

.search_box {
  width: 60%;
  height: 70%;
  border-radius: 10px;
  border-width: 3px;
  border-color: white;
  background-color: var(--main-background-colour);
  color: white;
  text-align: center;
  font-size: 50%;
  box-shadow: 5px 5px 2px lightgrey;
}

.search_list_container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.search_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1%;
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 80%;
}

.search_item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  padding: 10px;
  margin-top: 3%;
  border-radius: 5px;
  box-shadow: 5px 5px 2px lightgrey;
}

.search_link:visited {
  color: white;
  text-shadow: 5px 5px 5px grey;
}

.search_link:link {
  color: white
}

.idiom_not_found {
  width: 100%;
  text-align: center;
}

@media (max-width: 850px) {
  .search_list {
    grid-template-columns: repeat(1, 1fr);
    width: 100%;
    padding: 0;
    justify-items: center;
    align-items: center;
  }

  .search_item {
    padding: 1px;
    margin-top: 4%;
    width: 90%;
    font-size: 100%; 
  }
}
