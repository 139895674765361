@import url('https://fonts.googleapis.com/css2?family=Zeyada&display=swap');
:root {
  /* euans-website */
  --main-background-color:rgb(60, 123, 255);
  --font-color: rgb(227, 227, 227);
  
  /* Rasoi: */
  --primary-colour: blue;
  --secondary-colour: #f7a577;
  --grey-colour: #d3d3d3;
  --text-colour-white: white;
  --title-font: 'Zeyada'
}

html,
body{
  height: 100%;
  background-color: var(--main-background-color);
  padding: 0;
  margin: 0;
  font-family: "Epilogue", sans-serif;
  font-family: "Open Sans", sans-serif;
}

* {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  -o-user-select: none; /* Not implemented yet */
  user-select: none;
  scrollbar-color: #d4d0de; /* Firefox */
}

*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.199);
}

*::-webkit-scrollbar-thumb {
  background: #d4d0de;
}

*::-webkit-scrollbar:horizontal {
  height: 6px !important;
}

*::-webkit-scrollbar-track:horizontal {
  height: 6px !important;
}

#root {
  height: 100%;
}
