.ads_body {
  width: 100%;
  height: 100%;
  align-items: center;
}

.ads_header {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 200%;
}

.ads_top {
  width: 100%;
  height: 25%;
}

.ads_main {
  width: 100%;
  height: 25%;
  align-items: center;
  justify-content: center;
}

.sad {
  width: 100%;
  text-align: center;
  color: white;
  font-size: 150%;
}

.adsbygoogle {
  width: 100%;
  height: 100%;
}
