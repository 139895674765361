
.gallery_tile_container {
  width: 100%;
  background-color: var(--grey-colour);
}

.gallery_tile_title_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5%;
  width: 100%;
  background-color: var(--secondary-colour);
}

.gallery_tile_title {
  display: flex;
  align-items: center;
  font-family: var(--title-font);
  font-size: 7vh;
  padding: 0;
  margin: 0;
}

.carousel_container {
  margin: 0 20px;
  padding: 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;;
}

.carousel_container img {
  width: 100%;
  margin: 0;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button{
  border: none;
  background: #616161;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button{
  background: var(--secondary-colour) !important;
}

.react-multi-carousel-dot-list {
  margin: 1%;
  margin-bottom: 1.5%;
}
