.header {
  width: 100%;
  height: 15%;
  background-color: var(--secondary-colour);
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  padding: 0;
  margin: 0;
  color: var(--text-colour-white)
}

.header_menu {
  width: 60%;
  height: 100%;
  display: flex;
}

.menu_list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu_item {
  margin: 5%;
  font-family: var(--title-font);
  font-size: 5vh;
  color: black;
  text-decoration: None;
}

.menu_item:hover {
  text-decoration: underline;
}

.header_logo_container {
  display: flex;
  align-items: center;
  justify-content: right;
  width: 60%;
  height: 100%;
}

.title_logo {
  height: 60%;
  margin-right: 5%;
}

@media (max-width: 800px) {
  .header_menu {
    display: None;
  }

  .header_logo_container {
    width: 100%;
    justify-content: center;
  }

}
